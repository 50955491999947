
import { parse } from 'node-html-parser';

export const useExtractEmiOptionsFromMagentoBlock = (emiBlockData) => {
    const root = parse(emiBlockData);
    const emiOptionFigures = root.querySelectorAll('figure[data-content-type="image"]');
    
  let availableEmiOptions = [];

  emiOptionFigures.forEach((emiItem, index) => {
    let emiOption = {};

    let emiItemLinkTag = emiItem.querySelectorAll('a')[0];
    let desktopImageNode = emiItem.querySelectorAll('img[class="pagebuilder-mobile-hidden"]')[0];
    
    emiOption = {
        'logo': desktopImageNode.getAttribute('src') ?? '',
        'link': ('undefined' !== typeof emiItemLinkTag) ? (emiItemLinkTag.getAttribute('href') ?? '/') : '',
        'alt': desktopImageNode.getAttribute('alt') ?? '',
        'width': 100,
        'height': 30
    }

    availableEmiOptions = [...availableEmiOptions, emiOption];
  });
  
  return availableEmiOptions;
};

export default useExtractEmiOptionsFromMagentoBlock;
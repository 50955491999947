
























































































import { addBasePath } from '@vue-storefront/core';
import { SfImage, SfLink, SfList } from '@storefront-ui/vue';
import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import { useCmsBlocksStore } from '~/stores/cmsBlocks';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import useExtractEmiOptionsFromMagentoBlock from '~/composables/useCmsBlock/useExtractEmiOptionsFromMagentoBlock';

export default defineComponent({
  name: 'AvailableEmis',
  components: {
    SfImage,
    SfLink,
    SfList,
    SkeletonLoader,
    Picture: () => import(/* webpackPrefetch: true */ '~/components/Picture.vue'),
  },

  setup() {
    const availableEmiOptions = ref([]);
    const isLoading = ref(true);
    const cmsBlocksStore = useCmsBlocksStore();

    onMounted(async () => {
      availableEmiOptions.value = await getAvailableEmiOptions();
      setTimeout(() => {
        isLoading.value = false;
      }, 200);
    });

    async function getAvailableEmiOptions() {
      const blockData = cmsBlocksStore.blocks.cmsBlocks.find(block => block.identifier === "available-emi-options");
      const availableEmiOptionsList = useExtractEmiOptionsFromMagentoBlock(blockData?.content);

      return availableEmiOptionsList;
    }

    return {
      addBasePath,
      availableEmiOptions,
      isLoading
    };
  }
});
